import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';

const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/',
        
      },
      {
        path: '',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule)
      },
      {
        path: 'apps',
        children: [
          {
            path: 'chat',
            loadChildren: () => import('./pages/apps/chat/chat.module').then(m => m.ChatModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'mail',
            loadChildren: () => import('./pages/apps/mail/mail.module').then(m => m.MailModule),
            data: {
              toolbarShadowEnabled: true,
              scrollDisabled: true
            }
          },
          {
            path: 'social',
            loadChildren: () => import('./pages/apps/social/social.module').then(m => m.SocialModule)
          },
          {
            path: 'contacts',
            loadChildren: () => import('./pages/apps/contacts/contacts.module').then(m => m.ContactsModule)
          },
          {
            path: 'calendar',
            loadChildren: () => import('./pages/apps/calendar/calendar.module').then(m => m.CalendarModule),
            data: {
              toolbarShadowEnabled: true
            }
          },
          {
            path: 'aio-table',
            loadChildren: () => import('./pages/apps/aio-table/aio-table.module').then(m => m.AioTableModule),
          },
          {
            path: 'help-center',
            loadChildren: () => import('./pages/apps/help-center/help-center.module').then(m => m.HelpCenterModule),
          },
          {
            path: 'scrumboard',
            loadChildren: () => import('./pages/apps/scrumboard/scrumboard.module').then(m => m.ScrumboardModule),
          },
          {
            path: 'editor',
            loadChildren: () => import('./pages/apps/editor/editor.module').then(m => m.EditorModule),
          },
        ]
      },
      {
        path: 'user',
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'role',
        loadChildren: () => import('./pages/role/role.module').then(m => m.RoleModule)
      },      
      {
        path: 'asset-model',
        loadChildren: () => import('./pages/asset-model/asset-model.module').then(m => m.AssetModelModule)
      },
      {
        path: 'asset',
        loadChildren: () => import('./pages/asset/asset.module').then(m => m.AssetModule)
      },
      {
        path: 'device',
        loadChildren: () => import('./pages/device/device.module').then(m => m.DeviceModule)
      },
      {
        path: 'business-line',
        loadChildren: () => import('./pages/business-line/business-line.module').then(m => m.BusinessLineModule)
      },
      {
        path: 'supplier',
        loadChildren: () => import('./pages/supplier/supplier.module').then(m => m.SupplierModule)
      },
      {
        path: 'email-categories',
        loadChildren: () => import('./pages/email-categories/email-categories.module').then(m => m.EmailCategoriesModule)
      },
      {
        path: 'division',
        loadChildren: () => import('./pages/division/division.module').then(m => m.DivisionModule)
      },
      {
        path: 'warehouse',
        loadChildren: () => import('./pages/warehouse/warehouse.module').then(m => m.WarehouseModule)
      },    
      {
        path: 'geolocation',
        loadChildren: () => import('./pages/geolocation/geolocation.module').then(m => m.GeolocationModule)
      },   
      {
        path: 'process',
        loadChildren: () => import('./pages/process/process.module').then(m => m.ProcessModule)
      },
      {
        path: 'processOSM',
        loadChildren: () => import('./pages/process-osm/process-osm.module').then(m => m.ProcessOSMModule)
      },
      {
        path: 'idleness',
        loadChildren: () => import('./pages/idleness/idleness.module').then(m => m.IdlenessModule)
      },
      {
        path: 'usage',
        loadChildren: () => import('./pages/usage/usage.module').then(m => m.UsageModule)
      },
      {
        path: 'availability',
        loadChildren: () => import('./pages/availability/availability.module').then(m => m.AvailabilityModule)
      },
      {
        path: 'movement',
        loadChildren: () => import('./pages/movement/movement.module').then(m => m.MovementModule)
      },
      {
        path: 'cost-rate',
        loadChildren: () => import('./pages/cost-rate/cost-rate.module').then(m => m.CostRateModule)
      },
      {
        path: 'measurement',
        loadChildren: () => import('./pages/measurement/measurement.module').then(m => m.MeasurementModule)
      },
      {
        path: 'maintenance',
        loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenanceModule)
      },
      { 
        path: 'process-retroactive', 
        loadChildren: () => import('./pages/process-retroactive/process-retroactive.module').then(m => m.ProcessRetroactiveModule)
      },
      { 
        path: 'asset-category', 
        loadChildren: () => import('./pages/asset-category/asset-category.module').then(m => m.AssetCategoryModule)
      },
      {
        path: 'pages',
        children: [
          {
            path: 'pricing',
            loadChildren: () => import('./pages/pages/pricing/pricing.module').then(m => m.PricingModule)
          },
          {
            path: 'faq',
            loadChildren: () => import('./pages/pages/faq/faq.module').then(m => m.FaqModule)
          },
          {
            path: 'guides',
            loadChildren: () => import('./pages/pages/guides/guides.module').then(m => m.GuidesModule)
          },
          {
            path: 'invoice',
            loadChildren: () => import('./pages/pages/invoice/invoice.module').then(m => m.InvoiceModule)
          },
          {
            path: 'error-404',
            loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
          },
          {
            path: 'error-500',
            loadChildren: () => import('./pages/pages/errors/error-500/error-500.module').then(m => m.Error500Module)
          }
        ]
      },
      {
        path: 'ui',
        children: [
          {
            path: 'components',
            loadChildren: () => import('./pages/ui/components/components.module').then(m => m.ComponentsModule),
          },
          {
            path: 'forms/form-elements',
            loadChildren: () => import('./pages/ui/forms/form-elements/form-elements.module').then(m => m.FormElementsModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'forms/form-wizard',
            loadChildren: () => import('./pages/ui/forms/form-wizard/form-wizard.module').then(m => m.FormWizardModule),
            data: {
              containerEnabled: true
            }
          },
          {
            path: 'icons',
            loadChildren: () => import('./pages/ui/icons/icons.module').then(m => m.IconsModule)
          },
          {
            path: 'page-layouts',
            loadChildren: () => import('./pages/ui/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule),
          },
        ]
      },
      {
        path: 'documentation',
        loadChildren: () => import('./pages/documentation/documentation.module').then(m => m.DocumentationModule),
      },
      {
        path: '**',
        loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
