import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { InterceptorModule } from './interceptors/interceptor.module';
import { HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from './pages/common/paginator/custom-paginator';
import { AuthGuardService } from './services/auth-guard.service';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [AppComponent],
  imports: [
    MatProgressSpinnerModule,
    MatNativeDateModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InterceptorModule,
    JwtModule,
    // Vex
    VexModule,
    CustomLayoutModule
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    AuthGuardService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    JwtHelperService
  ],
  bootstrap: [AppComponent]
  
})
export class AppModule { }
